// 色指定

//基本の色系（このアプリにおける"赤"と言えば、みたいなイメージの定義。使ってみてよくなかったらこういう定義はやめる）
$colorBlue: rgba(0, 0, 205, 1);
$colorWhite: rgba(255, 255, 255, 1);
$colorBlack: rgba(0, 0, 0, 1);
$colorRed: rgba(255, 0, 0, 1);
$colorGrey: rgba(211, 211, 211, 1);
$colorOdakyu: rgb(0, 130, 205);

// 非活性なボタン
$disabledButtonBg: rgba(150, 150, 150, 1);
$disabledButtonBg: rgb(0, 130, 205);

// キャンセルや削除のボタン
$cancelButtonBg: rgba(120, 120, 120, 1);

// エラーメッセージの文字
$errorMessageColor: $colorRed;

//全体でよく使う枠線設定
$normalBorder: solid $colorBlack 1px;

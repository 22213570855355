// 何かのサイズとか、数値系を指定

//アプリとしての最小幅
$appMinWidth: 720px;

//フォント
$smallFontSize: 14px;
$baseFontSize: 16px;
$LargeFontSize: 32px;

//ホバーしたときに透過させるときの不透明度
$hoverOpacity: 0.8;

@import '../common/colors.module';
@import '../common/sizes.module';

.formArea {
    margin-top: 20px;
}

.container {
    margin: 30px 10px;

    //一般スマホの縦以外
    @media screen and (min-width: 480px) {
        //幅を決め、上下に良い感じに余白取って、中央に配置
        width: 500px;
        margin: 50px auto;
    }

    //テキスト系を中央に配置
    text-align: center;

    .pageTitle {
        font-size: $LargeFontSize;
    }

    //ボタン状態のロゴのラッパー
    .logoButton {
        //余白とって見た目調整
        padding: 5px;
        //高さは親要素に依存させる
        // height: 100%;
        align-items: center;

        //ロゴ実体
        img {
            height: 45px;
        }
    }
}

.confirmButton {
    background-color: rgb(0, 130, 205);
    color: rgba(255, 255, 255, 1);
}

.dashboardFrame {
    // margin: 50px 10px;

    // //一般スマホの縦以外
    // @media screen and (min-width: 480px) {
    //     //幅を決め、上下に良い感じに余白取って、中央に配置
    //     width: 500px;
    //     margin: 50px auto;
    // }

    //テキスト系を中央に配置
    text-align: center;

    // .pageTitle {
    //     font-size: $LargeFontSize;
    // }

    // //ボタン状態のロゴのラッパー
    // .logoButton {
    //     //余白とって見た目調整
    //     padding: 5px;
    //     //高さは親要素に依存させる
    //     // height: 100%;
    //     align-items: center;

    //     //ロゴ実体
    //     img {
    //         height: 45px;
    //     }
    // }
}

.passwordForgot {
    font-size: 12px;
    color: rgb(0, 130, 205);
    text-decoration: underline;
    cursor: pointer;
}

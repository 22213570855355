@import '../common/colors.module';
@import '../common/sizes.module';
@import '../common/modules.module';

.formArea {
    margin-top: 20px;
}

//ログイン入力フォーム
.inputArea {
    //上下余白
    padding: 10px 0;

    .inputTextFieldOutline {
        //枠線の色指定
        border-color: $normalBorder;
    }

    .signInButton {
        @include mediumButtonSize;
        @include buttonBase;

        &:disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }
}

.errorText {
    color: #ff0000;
    font-size: 12px;
    height: 18px;

    & .hidden {
        visibility: hidden;
    }
}

// お知らせ一覧
.notificationArea {
    // padding: 20px 0;
}

// パスワード変更フォーム
.inputPasswordArea {
    //上下余白
    padding: 10px 0;
    overflow: hidden;
    font-size: 9px;

    .inputTextFieldOutline {
        //枠線の色指定
        border-color: $normalBorder;
    }

    .signInButton {
        @include mediumButtonSize;
        @include buttonBase;

        //ボタンの大きさ
        width: 140px;
        height: 31px;
        margin-right: 24px;

        &:disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }

    .returnButton {
        font-size: 11px;
        margin-left: 19px;
    }
}

.changePasswordErrorText {
    color: #ff0000;
    font-size: 12px;
    height: 70px;

    & .hidden {
        visibility: hidden;
    }
}

.changePasswordRestartErrorText {
    color: #ff0000;
    font-size: 12px;
    height: 30px;

    & .hidden {
        visibility: hidden;
    }
}

.successText {
    color: #ff0000;
    font-size: 12px;
    height: 18px;

    & .hidden {
        visibility: hidden;
    }
}

.dialogText {
    font-size: 12px;
    // 改行反映
    white-space: pre-wrap;
}


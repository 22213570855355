@import '../common/colors.module';
@import '../common/sizes.module';

.menuIcon {
    margin-right: 0;
    margin-left: 0;
}

.pointer {
    cursor: pointer;
    color: #000000de;

    &:hover {
        background-color: rgb(245, 245, 245);
        color: #000000de;
    }

    &.active {
        background-color: rgb(225, 225, 225);
    }
}

.menuArea {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.dashboardMenu {
    flex: 1;
}

.closeButtonArea {
    height: 34px;
    text-align: right;
    // cursor: pointer;

    // &:hover {
    //     background-color: rgb(245, 245, 245);
    // }
}

.closeButton {
    // height: 24px;
    // cursor: pointer;
    margin: 0 0 0 0;
    padding: 9px;
    color: #918f8f !important;
    &:hover {
        background-color: rgb(245, 245, 245);
    }
    // &:hover {
    //     background-color: unset;
    // }
}

@import '../common/colors.module';
@import '../common/sizes.module';

.dashboardFrame {
    // margin: 50px 10px;

    // //一般スマホの縦以外
    // @media screen and (min-width: 480px) {
    //     //幅を決め、上下に良い感じに余白取って、中央に配置
    //     width: 500px;
    //     margin: 50px auto;
    // }

    //テキスト系を中央に配置
    text-align: center;

    label {
        height: 22px;
        display: block;
    }
}

@import '../common/colors.module';

.header {
    //両端にくっつける形で中身を均等配置
    display: flex;
    justify-content: space-between;
    //縦位置の中心に揃える
    align-items: center;

    background-color: rgb(0, 130, 205);

    min-height: 58px;

    //左詰めの要素
    .headerLeft {
        //高さは親要素に依存
        height: 100%;
        //均等に横並び
        display: flex;
        //縦位置の中心に揃える
        align-items: center;

        //ボタン状態のロゴのラッパー
        .logoButton {
            //余白とって見た目調整
            padding: 5px;
            //高さは親要素に依存させる
            height: 100%;

            //ロゴ実体
            img {
                height: 45px;
            }
        }

        //アプリタイトル
        .appTitle {
            color: $colorWhite;
            //デフォの上下マージンを消して左右に良い感じに余白
            margin: 0 10px;
            //何となくのサイズ
            font-size: 24px;
        }
    }

    .headerRight {
        //高さは親要素に依存
        height: 100%;
        //均等に横並び
        //今1個しか物がないけど
        display: flex;
        //縦位置の中心に揃える
        align-items: center;
    }

    .headerCenter {
        //高さは親要素に依存
        height: 100%;
        margin-left: auto;
        margin-right: auto;

        .appTitle {
            color: $colorWhite;
            //デフォの上下マージンを消して左右に良い感じに余白
            margin: 0 10px;
            //何となくのサイズ
            font-size: 20px;
            // 押せるようにしたのでカーソルを指に
        }
    }
}

.headerUnder {
    //両端にくっつける形で中身を均等配置
    display: flex;
    justify-content: flex-start;
    //縦位置の中心に揃える
    align-items: center;
    background-color: rgba(0, 159, 226, 1);
    height: 60px;
    padding-bottom: 5px;

    // //高さは親要素に依存
    // height: 100%;
    // //均等に横並び
    // //今1個しか物がないけど
    // display: flex;
    // //縦位置の中心に揃える
    // align-items: center;

    //アプリタイトル
    .appTitle {
        color: $colorWhite;
        //デフォの上下マージンを消して左右に良い感じに余白
        margin: 0 10px;
        //何となくのサイズ
        font-size: 20px;
        // 押せるようにしたのでカーソルを指に
        cursor: pointer;
    }

    .textField {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
        // width: 200,
    }

    .formControl {
        margin: 0 10px;
        // Width: 120;
    }
}

.iframeWrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}

.iframe-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 1000%;
    height: 1000%;
}

.labelGroup {
    color: $colorWhite;

    display: inline-block;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;

    label {
        font-size: 13px;
        font-weight: bold;
        display: block;
    }
}

.datePicker {
    margin-top: 0;
    margin-bottom: 0;

    input {
        width: 120px;
        font-size: 16px;
        color: $colorWhite;
    }

    button {
        padding: 0;
        vertical-align: unset;
    }
}

.stationSelect {
    color: $colorWhite;
    width: 120px;

    svg {
        color: $colorWhite;
    }
}

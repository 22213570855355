@import 'common/sizes.module';

.app {
    //中身が少なくても高さ100%
    min-height: 100%;
    //中身を縦ならべ
    display: flex;
    flex-direction: column;
    // 改行反映
    white-space: pre-wrap;
    //width: 100%;

    //各ページのメイン部分
    .mainContent {
        //ヘッダーとフッターがない部分いっぱいに伸長
        flex-grow: 1;
    }
}

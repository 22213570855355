//loaderの背景部分
.modal {
    position: relative;
    z-index: 1000000;
}

//loader本体
.loader {
    //位置を真ん中に調整
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    &:focus {
        //クリック時に枠線が出ないようにするため
        outline: none;
    }
}

@import 'common/sizes.module';

//moduleじゃない理由は#rootのセレクタを使用するため、module化すると名前がランダム文字列になってしまうため
//全体に効かせるつもりの設定を入れるファイルであり、moduleの意味はない

html,
body,
#root {
    //最小の高さは画面の縦いっぱいに
    // htmlとbodyはmin-heightがうまく働かないためheightは100％にしておく
    // 100％だと中身がそれを超過するときhtmlの高さが画面の高さのままだが、
    // 背景設定などはなぜか全体に聞くので問題ない
    height: 100%;
    // なぜかrootにもmin-heightではなくheightで高さ指定しないと子がrootの高さをとれないのでheightで100％指定(ブラウザバグらしい？)
    // それよりも子は.appにあてられた適切な設定からいろいろ受け継がせるので問題は無し

    //基本フォントサイズはHTMLに効かせたい
    font-size: $baseFontSize;
}

body {
    // フォント
    // これ参考にした https://ics.media/entry/200317/
    font-family: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, sans-serif;
    // この辺はCRAデフォ
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// CRAデフォで入っていたもの
// codeタグを使用する場合の見た目を指定
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// material-ui datePickerのCSS
.MuiPickersToolbar-toolbar {
    height: 50px !important;
    min-height: 50px !important;
    padding-top: 0;

    .MuiTypography-subtitle1 {
        font-size: 30px !important;
        color: rgba(255, 255, 255, 0.8);
    }
}

.MuiPickersDay-dayDisabled {
    color: #bebebe;
}

// 数値入力欄の▲▼マークを非表示にする
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}